<template>
  <div class="page_content_wrap">

	<div class="let_gets_to_know_you_wrap py-5">
		<div class="container">
			<div class="let_gets_to_know_you_box text-center">
                <h3 class="text-dark mb-3">Basic Annuity Terms</h3>
                <p>Before engaging with an annuity, let's review some commonly used terms.</p>
            </div>
            <div class="video-ifream text-center">
               <iframe src="//fast.wistia.com/embed/iframe/nms2wkzehw?videoFoam=true&amp;playbar=false" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" oallowfullscreen="oallowfullscreen" msallowfullscreen="msallowfullscreen" width="1000"  height="620"></iframe>
            </div>
		</div>
    </div>

    <div class="knowledge_toggle-section pb-5">
        <div class="container">
            <div class="knowledge_inner-box px-5">
                <div class="knowledge_toggle_box text-dark ">
                    <p class=" font-size-sm">Let's test your knowledge</p>
                    <p>Joe purchased a Midland National fixed index annuity 2 years ago and then passed away. What is the death benefit amount that is available to his beneficiaries?</p>
                </div>
                <!-- <div class="accordion" id="accordion_one">
                    <div class="accordion_box bg-pastel-success mb-3">
                        <div class="accordion_header border px-4">
                            <h5 class="collapsed" data-toggle="collapse" data-target="#accordion_one_1" aria-expanded="false">
                                01. Long-term Care
                            </h5>
                        </div>
                        <div id="accordion_one_1" class="collapse p-4" data-parent="#accordion_one" style="">
                            <div class="accordion_body">Long-Term Care prices have, historically, steadily increased. We should assume this trend will continue. Current national averages are approximately $7,000 per month.</div>
                        </div>
                    </div>
                    <div class="accordion_box bg-pastel-success mb-3">
                        <div class="accordion_header border px-4">
                            <h5 class="collapsed" data-toggle="collapse" data-target="#accordion_one_2" aria-expanded="false">
                                03. Healthcare Costs After Age 65
                            </h5>
                        </div>
                        <div id="accordion_one_2" class="collapse p-4" data-parent="#accordion_one" style="">
                            <div class="accordion_body">While Medicare premiums should be manageable, medical emergencies can carry out of pocket maximums as high as $7,000-$10,000.</div>
                        </div>
                    </div>
                </div>
                <div class="knowledge_toggle_box text-dark font-weight-bold">
                    <p><span class="text-success">Correct -</span> The Death Benefit equals at least the accumulation value, but state law may require it to be higher if the accumulation value doesn’t meet a certain minimum required by state law.</p>
                </div> -->
            </div>
        </div>
    </div>

    <!-- Tabs -->
    <section class="pb-5 information-section" id="tabs">
        <div class="container">
            <div class="information-inner px-5">
                <h6 class="section-title text-dark">Key Information</h6>
                <p class="text-dark">Below are the index crediting methods available for your product. Detailed information on each method can be found in the “More Information” section below.</p>
                <div class="row">
                    <div class="col-12">
                        <nav>
                            <div class="nav nav-tabs nav-fill mb-4" id="nav-tab" role="tablist">
                                <a class="text-left active h6" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">MNL ENDEAVOR 12</a>
                            </div>
                        </nav>
                        <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <table class="table table-striped text-dark">
                                    <thead>
                                    <tr>
                                        <th>CREDITING METHOD</th>
                                        <th>GUARANTEED RATE</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>FIXED RATE</td>
                                        <td>Minimum 0.25%</td>
                                    </tr>
                                    <tr>
                                        <td>MONTHLY POINT-TO-POINT WITH CAP RATE</td>
                                        <td>Minimum 0.25%</td>
                                    </tr>
                                    <tr>
                                        <td>ANNUAL POINT-TO-POINT WITH PARTICIPATION RATE</td>
                                        <td>Minimum 5.00%</td>
                                    </tr>
                                    <tr>
                                        <td>INVERSE PERFORMANCE TRIGGER</td>
                                        <td>Minimum 0.25%</td>
                                    </tr>
                                    <tr>
                                        <td>ANNUAL POINT-TO-POINT WITH INDEX CAP</td>
                                        <td>Minimum 0.50%</td>
                                    </tr>
                                    <tr>
                                        <td>ANNUAL POINT-TO-POINT WITH INDEX MARGIN</td>
                                        <td>Maximum 15.00%</td>
                                    </tr>
                                    <tr>
                                        <td>DAILY AVERAGE</td>
                                        <td>Maximum Daily Margin 25.00%</td>
                                    </tr>
                                    <tr>
                                        <td>DEATH BENEFIT</td>
                                        <td>The greater of your Accumulation Value or 87.5% of all premiums less any money taken from the contract accumulated at a minimum guaranteed rate of 1.00%</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<!-- ./Tabs -->

    <div class="more_information_section pb-5">
        <div class="container">
            <div class="knowledge_inner-box px-5">
                <div class="accordion" id="accordion_one">
                    <div class="accordion_box mb-3">
                        <div class="accordion_header border px-4">
                            <h5 class="collapsed" data-toggle="collapse" data-target="#information" aria-expanded="false">More information</h5>
                        </div>
                        <div id="information" class="collapse p-4" data-parent="#information">
                            <div class="accordion_body text-dark">
                                <div class="accumulation pb-3">   
                                    <h6>What is my Accumulation Value?</h6>
                                    <p class="font-size-sm">Your Accumulation Value is equal to 100% of the premium you add to the annuity, plus any interest earned, minus any withdrawals from the annuity. The Accumulation Value is used to determine the Death Benefit as well as the amount of penalty-free withdrawals available each year.</p>
                                </div>
                                <div class="accumulation pb-3">   
                                    <h6>What is my Death Benefit and when does it become payable?</h6>
                                    <p class="font-size-sm">The Death Benefit equals the Accumulation Value or the Minimum Surrender Value as defined in the contract, whichever is greater. The beneficiary may choose to receive the Death Benefit in either a lump sum or a series of payments.</p> 
                                    <p class="font-size-sm">The Death Benefit is payable upon the death of the first owner or upon the death of the second annuitant, whichever is first. However, a death benefit is not available if you choose to annuitize your contract and receive an annuity payout option, which may include payments to your beneficiaries after your death depending on which option you choose.</p>
                                </div>
                                <div class="accumulation pb-3">   
                                    <h6>How will interest be credited to my annuity?</h6>
                                    <p class="font-size-sm">You can allocate your premium between one or both of the following accounts, which credit interest in different ways:</p>
                                </div>
                                <div class="accumulation pb-3 pl-3">   
                                    <p class="mb-1">Fixed Account</p>
                                    <p class="font-size-sm">The portion of premium allocated to this account will receive the current Fixed Account interest rate. This rate will be guaranteed for the first contract year. The rate for future contract years will be declared annually thereafter at Midland National’s discretion. Midland National will never declare a rate that is lower than the Minimum Guaranteed Fixed Interest Rate shown in the gray box.</p>
                                </div>
                                <div class="accumulation pb-3 pl-3">   
                                    <p class="mb-1">Index Account</p>
                                    <p class="font-size-sm">The portion of premium allocated to this account provides the potential to receive interest based on the chosen crediting method and change in the applicable external index during each contract year. Available crediting methods may vary by product. Allocation in an Index Account is not an investment in the stock market. See the information in the gray box to determine which crediting methods are available to you based upon the products in your plan.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="disclaimer py-6">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="more-information text-dark">
                        <h5>Disclaimer</h5>
                        <p>The MNL Endeavor 12 is issued by Midland National® Life Insurance Company, West Des Moines, IA. This product, its features and riders may not be available in all states. Please request the product disclosure for additional details and limitations.</p>
                        <p class="mb-0">Fixed Index Annuities are not a direct investment in the stock market. They are long term insurance products with guarantees backed by the issuing company. They provide the potential for interest to be credited based in part on the performance of specific indices, without the risk of loss of premium due to market downturns or fluctuation. Although fixed index annuities guarantee no loss of premium due to market downturns, deductions from your accumulation value for additional optional benefit riders could under certain scenarios exceed interest credited to the accumulation value, which would result in a loss of premium. They may not be appropriate for all clients.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer_bottom py-4">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="back-box d-inline-block pt-3">
                        <a class="text-dark h5 text-decoration-none" href="#"><i class="far fa-arrow-left mr-2"></i>Back</a>
                    </div>
                    <div class="understand-box d-inline-block float-right">
                        <p class="d-inline-block mr-3 text-dark">Please watch the video to continue</p>
                        <a href="#" class="btn btn-primary btn-lg">I Understand, Continue<i class="far fa-arrow-right ml-2"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {

}
</script>

<style>

</style>